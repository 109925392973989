import Banner from "./components/Banner";
import Section2 from "./components/Section2";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Section7 from "./components/Section7";
import Section8 from "./components/Section8";

function App() {
  return (
    <div className="bg-gray-50 card-scroll">
      <Banner />
      <Section2 />
      <Section4 />
      <br />
      <br />
      <Section5 />
      <br />
      <br />
      <Section6 />
      <br />
      <br />

      <Section7 />
      <Section8 />
    </div>
  );
}

export default App;
