import { BiLeaf } from "react-icons/bi";
const Section6 = () => {
  return (
    <div>
      <div className="px-2 sm:px-6 mx-auto flex flex-wrap py-6 ">
        <section className="w-full flex flex-col items-center px-3">
          <article className="grid sm:grid-cols-3 shadow my-4">
            <img
              className="hover:opacity-75 h-[22rem] sm:h-[20rem] w-full rounded-md object-cover"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695023233/medical-med/new/dnlam5a01lvej28laacf.jpg"
            />

            <div className="bg-white sm:col-span-2 flex flex-col justify-start p-2 sm:p-6 ">
              <p className="text-2xl font-bold hover:text-gray-700 pb-4">
                ለሀይልኛ መስተፋቅር{" "}
              </p>
              <p className="text-sm pb-3 text-gray-600">በመርጌታ ይግዛው</p>
              <div className="pb-6 p-2 sm:p-4 sm:h-[11rem] tracking-wide text-gray-800 sm:overflow-y-scroll card-scroll">
                <p className="pb-3 flex space-x-3 items-center ">
                  <BiLeaf  className="text-green-500 "/>
                  <span className="font-semibold"> ይቺ ዕፅ ደግሞ ደንደሮ ትባላለች!</span>
                </p>
                <p>
                  ትክክለኛዋ አይገኝም እንጂ ከተገኘ ሀይለኛ መስተፋቅር ነች፡፡ ምንድነው እዚጋ በይፋ የማላወጣው
                  የራሱ የሆነ ጸሎት አስማት ይደገምበትና በጥርስህ እያኘከው ወንድም ከሆንክ የፈለካትን ሴት ሴት
                  ከሆንሽ የፈለክሽውን ወንድ የፈለከውን ብትጠይቅ መልሱ ወዲያው አሸሺ ነው፡፡ ጸሎቱ ካልተደገመበት
                  ግን አይሰራም፡፡
                </p>
                <p>
                  በውጭ ሀገር ለምትኖሩ እና አገር ውስጥ ሁናቹ መምጣት ላልቻላቹ ባላቹህበት ሁናቹ አገልግሎት ማግኘት
                  ትችላላቹ!
                </p>
              </div>
            </div>
          </article>
        </section>
      </div>
    </div>
  );
};

export default Section6;
