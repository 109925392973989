import { BsTelephoneFill } from "react-icons/bs";
import { FaTelegram } from "react-icons/fa";

const Section8 = () => {
  return (
    <div className="flex w-full bg-gray-100 p-10 justify-center mx-auto">
      <div className="flex-col flex justify-center items-center">
        <img
          className="w-64 h-64 rounded-full border-white border-8 shadow-sm object-cover"
          src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021392/medical-med/new/tgdy7ldzbbvpxrsefncq.jpg"
          alt=""
        />

        <div className="text-center mt-4">
          <h3 className="font-semibold text-xl pb-2 text-gray-700">
            መርጌታ ይግዛው
          </h3>
          <h1 className="text-green-500 font-bold text-3xl">
            የባህል መድሀኒት ፣ አስማት ፣ ጥበብና ህክምና
          </h1>

          <p className="text-gray-600 py-5">
            ዉድ ወንድሞቼና እህቶቼ ትክክለኛና ተፈትነው በብዙ ሰዎች ዘንድ ተቀባይነት ያተረፉ የባህል መድሀኒቶች እኛ
            ዘንድ ያገኛሉ
          </p>
        </div>
        <div>
          <div className="flex space-x-4 items-center text-xl text-gray-700 mb-2">
            <BsTelephoneFill className="text-gray-700" />{" "}
            <div className="flex flex-col">
              <a className="text-base mb-2 " href="tel:+251923456635">
                +251 923456635
              </a>
              <a className="text-base mb-2 " href="tel:+251938467567">
                +251938467567
              </a>
            </div>
          </div>

          <div className="flex space-x-4 items-center text-xl text-gray-700">
            <FaTelegram className="text-gray-700" />{" "}
            <a className="text-base" href="https://t.me/MergetaYigzaw">
              Contact us on Telegram
            </a>
          </div>
        </div>
        <p className="text-center border-gray-300 border-t-2 pt-6 text-gray-700 mt-8">
          ደዉለው ሀሳብና ጥያቄዎን ያጋሩን
        </p>
      </div>
    </div>
  );
};

export default Section8;
