import { BsTelephoneFill } from "react-icons/bs";
import { FaLeaf } from "react-icons/fa";

const Section2 = () => {
  return (
    <div className="mt-44 mx-auto flex flex-wrap py-6">
      <section className="w-full md:w-2/3 flex flex-col items-center px-3">
        <article className="flex flex-col shadow my-4">
          <img
            alt="mergeta images"
            className="object-cover"
            src="https://res.cloudinary.com/djtkzulun/image/upload/v1694900785/medical-med/nokd7gb6xge6h0uzrgoy.jpg"
          />
          {/* </a> */}
          <div className="bg-white flex flex-col justify-start p-6">
            <p className="text-3xl font-bold text-green-600 pb-4">
              መፍትሔ ሥራይ ወዝክረ ጥበብ
            </p>
            <p className="text-sm pb-8 text-gray-600">
              መርጌታ ይግዛው የባህል መድሀኒት ፣ አስማት ፣ ጥበብና ህክምና
            </p>
            <h1 className="text-2xl font-bold pb-6 text-gray-900">
              ከምንሰጣቸዉ አገልግሎቶች ውስጥ
            </h1>

            <div className="grid grid-cols-1 text-gray-800 sm:grid-cols-2 gap-4 pb-8">
              <ul className="pl-4 space-y-2">
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለመፍትሄ ስራይ
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለቡዳ
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለቁራኛ
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለመጠብቅ (ከጠላት፡ከሚላክ
                  ሟርት
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለግርማ ሞገስ
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለጥይት (መሳሪያ የማያስነካ)
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለብትር (ዱላ የማያስነካ)
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለጋኔን
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለማንኛዉም አይነት የሰዉነት
                  ክፍል በሽታ
                </li>
              </ul>
              <ul className="pl-4 space-y-2">
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለእቁብ
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለመስተሳልም
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ትቶን የሄደን ሰዉ ለማስመለስ
                  (ለፍቅረኛ)
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለስንፈተ ወሲብ
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ዘኢያወርድ (ለወንዶች በፆታዊ
                  ግንኙነት ጊዜ የዘር ፍሬ ቶሎ እንዳይፈስ)
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለመፍትሄ ሀብት (በማንኛዉም
                  ስራ ገንዘብ፡ሀብት እንዲኖረን)
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለገበያ
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለወር አበባ ችግር
                </li>
                <li className="flex items-center">
                  <FaLeaf className="text-green-500 mr-2" /> ለአይነ ጥላ (የፍርሃት መንፈስ
                  ማስለቀቂያ)
                </li>
              </ul>
            </div>
            <div className="text-gray-700">
              <p className="pb-2">
                ዉድ ወንድሞቼና እህቶቼ ትክክለኛና ተፈትነው በብዙ ሰዎች ዘንድ ተቀባይነት ያተረፉ የባህል መድሀኒቶች
                እኛ ዘንድ ያገኛሉ
              </p>
              <div className="flex flex-row space-x-2 pb-1">
                <BsTelephoneFill />
                <a href="tel:+251923456635">+251923456635</a>
              </div>
              <div className="flex flex-row space-x-2 pb-2">
                <BsTelephoneFill />
                <a href="tel: +251938467567">+ 251938467567</a>
              </div>
              <p>ደዉለው ሀሳብና ጥያቄዎን ያጋሩን !</p>{" "}
            </div>
          </div>
        </article>
        <div className="opacity-5 select-none hover:opacity-10 hidden md:block border-b-2 py-20">
          <p className="text-4xl lg:text-5xl ">መርጌታ ይግዛው</p>
          <p className="text-6xl lg:text-7xl ">የባህል መድሀኒት ፣ ጥበብና ህክምና</p>
        </div>
      </section>

      <div className="w-full md:w-1/3 flex flex-col items-center px-3">
        <div className="w-full bg-white shadow flex flex-col my-4 p-6">
          <p className="text-xl font-semibold pb-5"> አገልግሎቱን የምንሰጥባቸው ቁሳቁሶች</p>

          <div className="grid grid-cols-3 gap-3">
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021392/medical-med/new/yl7ja5ssvkxcduvemjn7.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021396/medical-med/new/knqcwogckjb3pelsovzp.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full "
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/njhicer0iw7e0yxrsido.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/xwjmgpnkrntn1wmmy2h9.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/hivvf3mxopdwvm3hro1v.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/paaqliryqrmfqcogbafa.jpg"
            />
          </div>

          <ul className="pl-4 space-y-2 mt-4 text-gray-700 font-medium">
            <li className="flex items-center">
              <FaLeaf className="text-green-500 mr-2" /> ሞአንበሳ ዘይምነ ገዳ ቀለበት
            </li>
            <li className="flex items-center">
              <FaLeaf className="text-green-500 mr-2" /> መዳብ
            </li>
            <li className="flex items-center">
              <FaLeaf className="text-green-500 mr-2" /> በተለያዩ የእፅዋት ቅመማዎች
              /ተቀፅላ/
            </li>
            <li className="flex items-center">
              <FaLeaf className="text-green-500 mr-2" /> በዉቅራት /በንቅሳት/
            </li>
            <li className="flex items-center">
              <FaLeaf className="text-green-500 mr-2" /> በእንስሳት ቆዳና ክታበ ስጋ
            </li>
            <li className="flex items-center">
              <FaLeaf className="text-green-500 mr-2" /> በጥፈት
            </li>
            <li className="flex items-center">
              <FaLeaf className="text-green-500 mr-2" /> በተለያዩ ሚስጢር በሆኑ ነገሮች
            </li>
          </ul>
        </div>

        <div className="w-full bg-white shadow flex flex-col my-4 p-6">
          <p className="text font-semibold pb-5">
            መርጌታ ይግዛው የባህል መድሀኒት ፣ አስማት ፣ ጥበብና ህክምና
          </p>
          <div className="grid grid-cols-3 gap-3">
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021392/medical-med/new/tgdy7ldzbbvpxrsefncq.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021396/medical-med/new/knqcwogckjb3pelsovzp.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full "
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/br2cbpd04n52onpuokf3.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/xwjmgpnkrntn1wmmy2h9.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/hivvf3mxopdwvm3hro1v.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/p7lsnqkcfayy75id4hdt.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/tgidyyy9nlwsyl8ozxm1.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/o99luuqwhvytqlzlfxoy.jpg"
            />
            <img
              alt="mergeta images"
              className="hover:opacity-75 h-36 object-cover w-full"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021395/medical-med/new/ioindec76rpkea0xjlzg.jpg"
            />
          </div>
          <div className="pt-4 text-gray-700">
            <p className="">
              ይደዉሉ ከማያልቀው ከአባቶቻችን ከእውቀት ማዕድ ይካፈሉ ፤ ለችግሮቻቹ መፍተሔ ያግኙ !
            </p>
            <hr className="my-2" />
            <div className="flex py-2 tracking-wider text-xl space-x-2 font-medium items-center">
              <BsTelephoneFill className="text-green-500" size={26} />{" "}
              <div className="flex flex-col">
                <a className="text-base" href="tel:+251923456635">
                  +251 923456635
                </a>
                <a className="text-base" href="tel:+251938467567">
                  +25 1938467567
                </a>
              </div>
            </div>
          </div>
          <a
            href="https://t.me/MergetaYigzaw"
            className="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-6"
          >
            <i className="fab fa-instagram mr-2"></i>ቴሌግራማችንን ይቀላቀሉ
          </a>
        </div>
      </div>
    </div>
  );
};

export default Section2;
