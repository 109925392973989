import { BiLeaf } from "react-icons/bi";
const Section5 = () => {
  return (
    <div>
      <div className="px-2 sm:px-6 mx-auto flex flex-wrap py-6 ">
        <section className="w-full flex flex-col items-center px-3">
          <article className="grid sm:grid-cols-3 shadow my-4">
            <img
              className="hover:opacity-75 h-[22rem] sm:h-[30rem] w-full rounded-md object-cover"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695023233/medical-med/new/dcedddbkzuvxltvwpcd8.jpg"
            />

            <div className="bg-white sm:col-span-2 flex flex-col justify-start p-2 sm:p-6 ">
              <p className="text-2xl font-bold hover:text-gray-700 pb-4">ጉድይኝ</p>
              <p className="text-sm pb-3 text-gray-600">በመርጌታ ይግዛው</p>
              <p className="pb-6 p-2 sm:p-4 sm:h-[21rem] tracking-wide text-gray-800 sm:overflow-y-scroll card-scroll">
                ጉድኝ በብዛት በደጋማው ሀገርና በወንዛንዝ በጎድጓዳ ውሃ ፈረጥ ቦታ የምትበቅል እፅዋት ስትሆን
                የተለያየ ጥቅም እንዳላት ከሊቃውንቶች ውጭ ብዙም የሚያውቅ የለም
                <br />
                ነገር ግን የተለያየ ጥቅምና አገልግሎት ያላት ወሳኝና አስፈላጊ እፅዋት ናት!
                <br />
                <br />
                <span className="font-bold text-lg">ከጥቅሞቿ በጥቂቱ</span> <br />
                <br />
                <span className="font-semibold flex items-center space-x-2 ">
                  {" "}
                  <BiLeaf  className="text-green-500"/> <span>ለመፍዝዝ</span>{" "}
                </span>{" "}
                ማክሰኞ ቀን በጥዋት እፅ አንግስ ፯. (7) ጊዜ ደግሞ በወይራ አንካሴ ቆፍሮ የቀረጥ ተቀፅላ
                የጠንበለል ተቀፅላ የክትክታ ተቀፅላ የኤሊ አፅም ጨምሮ አንግሶ ከትቦ ቢይዙት ከጠላት ከምቀኛ ይሰውራል
                ለጠላት አያስነካም።
                <br />
                <br />
                <span className="font-semibold flex items-center space-x-2 ">
                  <BiLeaf  className="text-green-500"/> <span>ለክፉ መንፈስ</span>{" "}
                </span>{" "}
                ቅጠሏን ጭቅጭቆች በውሃ ዘፍዝፎ ጥዋት ጥዋት ፯. (7 ) ማጥመቅ መጠመቅ። ክፉ ቁራኛን የዛር ውላጅን
                ያስለቅቃል።
                <br />
                <br />
                <span className="font-semibold flex items-center space-x-2 ">
                  {" "}
                  <BiLeaf  className="text-green-500"/> <span>ለለቤትሰላቢ</span>{" "}
                </span>{" "}
                መመለሻ የጉድኝ ስር የአንባጮ ተቀፅላ የዘርጭ እንቧይ ስር ሁሉንም በአንድ ከትቦ አንድም በገንዘብ
                ማስቀመጫ ሳጥን ማስቀመጥ። ሁለተኛ ከቤት ከዱቄትና ከሊጥ ስር ማስቀመጥ ነው
                <br />
                <br />
                ጠቅላላ የገንዘብ (ብር ) የእህል የሊጥ የዱቄት ሰላቢ በረከት የሚያሳጣ ነገርን በሙሉ ያባራል ከቤት
                አያስገባም ።
              </p>
            </div>
          </article>
        </section>
      </div>
    </div>
  );
};

export default Section5;
