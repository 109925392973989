import { useState, useEffect } from "react";
import {
  BsChevronCompactLeft,
  BsChevronCompactRight,
  BsTelegram,
  BsTelephoneFill,
} from "react-icons/bs";
import { RxDot, RxDotFilled } from "react-icons/rx";

const Banner = () => {
  const slides = [
    {
      url: "https://res.cloudinary.com/djtkzulun/image/upload/v1694900784/medical-med/fo9csqv4f2gcteocafye.jpg",
      text: "ቀደምት አባቶቻችን በዕፅዋት ጥበብ የሚገኙ መፍትሔዎችን አስቀምጠውልን አልፈዋል",
    },
    {
      url: "https://res.cloudinary.com/djtkzulun/image/upload/v1694900785/medical-med/nokd7gb6xge6h0uzrgoy.jpg",
      text: "ከማያልቀው ከአባቶቻችን ከእውቀት ማዕድ ይካፈሉ ፤ ለችግሮቻቹ መፍተሔ ያግኙ",
    },

    {
      url: "https://res.cloudinary.com/djtkzulun/image/upload/v1694901720/medical-med/lx8hn8gz7stto33wpamt.jpg",
      text: "ትክክለኛና ተፈትነው በብዙ ሰዎች ዘንድ ተቀባይነት ያተረፉ የባህል መድሀኒቶች እኛ ዘንድ ያገኛሉ",
    },
    {
      url: "https://res.cloudinary.com/djtkzulun/image/upload/v1694892754/medical-med/cgftwvnrvuan9d2mjtev.jpg",
      text: "በዘመን አመጣሽ መድሀኒቶች ፍፁም መፍትሄ ሊገኝላቸው ያልቻሉ ሀያል ችግሮች በአባቶቻችን  በተቀመሙ ዕፅዋቶች ተፈውሰዋል",
    },
    {
      url: "https://res.cloudinary.com/djtkzulun/image/upload/v1694892754/medical-med/cxizy92xzldjuxj3ctky.jpg",
      text: "ከጥንት ኣቢሲንያ ሲወርድ ፥ ሲዋረድ የመጣው ጥበብ ዛሬ ላይ ከሀገር አልፎ በዉጪ ለሚኖሩ ወገኖቻችን ትልቅ እፎይታ እየሰጠ ይገኛል",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      nextSlide();
    }, 4000);
    return () => clearInterval(slideInterval);
  }, [currentIndex]);

  return (
    <div className="h-[28rem] w-full m-auto py-0 relative group">
      <div className="flex flex-col sm:flex-row justify-between items-center py-4 px-6">
        <div>
          <h1 className="text-gray-600 text-base sm:text-lg font-semibold">
            መርጌታ ይግዛው
          </h1>
          <h2 className="text-green-500 text-xl sm:text-2xl font-bold">
            የባህል መድሀኒት ፣ ጥበብና ህክምና
          </h2>
          <hr className="my-2 sm:hidden" />
        </div>

        <div className="font-semibold flex sm:flex-col justify-between w-full sm:w-auto text-gray-700">
          <div className="flex space-x-2 items-center">
            <BsTelegram />
            <a className="hidden sm:block" href="https://t.me/MergetaYigzaw">MergetaYigzaw</a>
            <a className="block sm:hidden" href="https://t.me/MergetaYigzaw">Telegram</a>
          </div>

          <div className="flex space-x-2 items-center text-xs sm:text-base">
            <BsTelephoneFill />
            <div className="flex flex-col">
              <a className="text-base" href="tel:+251923456635">
                +251 923456635
              </a>
              <a className="text-base" href="tel:+251938467567">
              +251 938467567
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-full justify-center items-center flex-col">
        <div
          className="w-full bg-blend-hard-light h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${slides[currentIndex].url})`,
          }}
        >
          <div className="w-full h-full flex  justify-center items-center backdrop-brightness-[40%]">
            <span className="text-gray-200 text-lg sm:text-xl lg:text-4xl sm:p-4 sm:px-8  lg:p-8 lg:px-20 leading-relaxed tracking-wide bg-black bg-opacity-20 w-11/12 sm:w-2/3 text-center">
              {slides[currentIndex].text}
            </span>
          </div>
        </div>
      </div>

      <div className="hidden group-hover:block absolute top-[70%] -translate-x-0 translate-y-[-70%] left-5 text-2xl rounded-full p-2 bg-black/20 hover:bg-primary text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className="hidden group-hover:block absolute top-[70%] -translate-x-0 translate-y-[-70%] right-5 text-2xl rounded-full p-2 bg-black/20 hover:bg-primary text-white cursor-pointer">
        <BsChevronCompactRight
          className="hover:bg-primary"
          onClick={nextSlide}
          size={30}
        />
      </div>
      <div className="flex top-4 justify-center py-2 z-10">
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className="text-2xl cursor-pointer"
          >
            {slideIndex == currentIndex ? <RxDotFilled /> : <RxDot />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Banner;
