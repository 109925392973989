import { BiLeaf } from "react-icons/bi";
const Section4 = () => {
  return (
    <div>
      <div className="px-2 sm:px-6 mx-auto flex flex-wrap py-6 ">
        <section className="w-full flex flex-col items-center px-3">
          <article className="grid sm:grid-cols-3 shadow my-4">
            <img
              className="hover:opacity-75 h-[22rem] sm:h-[30rem] w-full rounded-md object-cover"
              src="https://res.cloudinary.com/djtkzulun/image/upload/v1695021396/medical-med/new/zijlfdfiwxeyquvyfwd9.jpg"
            />

            <div className="bg-white sm:col-span-2 flex flex-col justify-start p-2 sm:p-6 ">
              <p className="text-2xl font-bold hover:text-gray-700 pb-4">
                ከሲጋራ እና መሰል ሱስ ለመዳን/ለመውጣት
              </p>
              <p className="text-sm pb-3 text-gray-600">በመርጌታ ይግዛው</p>
              <p className="pb-6 p-2 sm:p-4 sm:h-[21rem] tracking-wide text-gray-800 sm:overflow-y-scroll card-scroll">
                እንጎቺት፣የአይጥ ሐረግ፣ዕጸ ኢየሱስ እየተባለች የምትጠራው ዕጽ የሚስማማት መልክዓ ምድር ወይና ደጋ
                ሲሆን የሚያማምሩ ክብ መሳይ እና የልብ ቅርጽ ቅጠል ባለቤት ስትሆን፤ መደብዋ የሐረግ ዝርያ
                ስትሆን፤ስርዋ ድንች የሚመስል ግዙፍ አካል ነው።
                <br />
                <br />
                <span className="font-semibold">ዕጸ_ኢየሱስ</span> ባለ ብዙ መፍትሔ እና
                ከተለያዩ ዕጽዋቶች ጋር ተቀምማ ብዙ መፍትሔ ትሰጣለች። ሊቃውንቶች እና ጠበብት በተለያየ ገቢር
                የተለያየ አቆራረጥን በመከተል ልዩ ልዩ እና አስደናቂ ምጢራትን ይጠቀሙባታል።
                <br />
                <br />
                <span className="font-semibold ">ዕጸ_ኢየሱስ </span>
                <br />
                <div className="flex items-center space-x-2">
                  {" "}
                  <BiLeaf className="text-green-500" />
                  <p>ለመንድግ ወይንም</p>
                </div>
                <div className="flex items-center space-x-2">
                  {" "}
                  <BiLeaf className="text-green-500" />
                  <p>ለሀብት ለመሰውር</p>
                </div>
                <div className="flex items-center space-x-2">
                  {" "}
                  <BiLeaf className="text-green-500" />
                  <p>ለመክስተ ምስጢር</p>
                </div>
                <div className="flex items-center space-x-2">
                  {" "}
                  <BiLeaf className="text-green-500" />
                  <p>ለመስተዋድድና</p>
                </div>
                <div className="flex items-center space-x-2">
                  {" "}
                  <BiLeaf className="text-green-500" />
                  <p>ለተለያዩ የስጋ ደዌ መፍትሔ እንደምትሆን ሊቃውንት በማህደራኣቸው ያስቀምጣሉ።</p>
                </div>
                <br />
                ዛሬ ስለዕጸ ኢየሱስ ወይንም እንጎቺት የምናየው መፍትሔ ቀለል ያለ እና ለሀገራችን ኃይል መዳከም ዋነኛ
                አስተዋጽኦ ያሳደረ ለጤናም ጠንቅ የሆነ ችግር ወይንም ሱስ በሲጋራ ሱስ የተጠመዳቹ ለመላቀቅ ግብግብ
                እየፈጠራቹ ግን የሲጋራ ሱስ አሸንፏቹ፤የበታችነት ስሜት ለሚሰማቹ ወንድ ሴት ወጣት ሽማግሌ በሙሉ
                ይመለከታል። <br />
                <br />
                <span className="font-bold text-lg">አጠቃቀም</span> <br />
                የዕጸ ኢየሱስ.የእንጎቺት ወይንም የአይጥ ሐረግ ቅጠል አድርቃቹ አልማቹ ደቁሳቹ ሱጋራ ማጨስ
                በምትፈልጉበት ሰዓት የተደቀቀው ቅጠል የሲጋራ ግማሽ አካሉ ሰርስራቹ አውጥታቹ፤የዕጸ ኢየሱስ ቅጠል
                በመሀል አስገብታቹ በወጣው የሲጋራ ፍርፋሪ ደፍናቹ፤ሲጋራውን ማቀጣጠል እና ማጨስ እጅግ እንድትጠሉት
                ይሆናል ግን ይህ ድርጊት በተደጋጋሚ መውሰድ አለባቹ ቢያንስ ሲጋራውን እስክትጠሉት ድረስ ሙሉ ለሙሉ
                ከሱሱ ለመላቀቅ ከሳምንት እስከ 3 ሳምንት ሊፈጅባቹ ይችላል።
                <br />
                <br />
                በፍጥነት ሱሱን ለማቆም ቢፈለግ ከዕጸ ኢየሱስ ቅጠል ጋር ንጹህ የተፈጥሮ ስርኩል ጨምሩበት።
              </p>
            </div>
          </article>
        </section>
      </div>
    </div>
  );
};

export default Section4;
